import React from 'react'
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { ReactTinyLink } from 'react-tiny-link';
import { useEffect } from 'react';
import { useState } from 'react';
import Linkify from 'react-linkify';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { color } from '../../../../hooks/Utils/color';
import { useTranslation } from 'react-i18next';
import SondEvent from '../SondEvent/SondEvent';
import { TextWithMentionsAndHashtags, removeSpecialCharacters, replaceSpecialCharacters } from 'hooks/useRenderText/useRenderText';
import { fetchTranslateText } from 'hooks/useTranslationText/useTranslationText';
import TextTranslate from 'components/TextTranslate/TextTranslate';


const SondCaption = ({
    text,
    opinion,
    setViewOpinion,
    sourceFrom
}) => {

    const { t, i18n } = useTranslation()
    const [isEnLang, setIsEnLang] = useState(i18n.language === 'en')
    const navigate = useNavigate()

    const [hasBackgroundColor, setHasBackgroundColor] = useState(
        !opinion?.sond_background || opinion?.sond_background === '#FFFFFF'
            ? false
            : true
    )

    const MAX_LENGHT = 1000
    const [viewMore, setViewMore] = useState(false)

    const [link, setLink] = useState(null)
    const [meta, setMeta] = useState({})


    const goToViewOpinion = () => {
        if (sourceFrom === 'ViewOpinion') {
            return
        }
        navigate(`/opinion/select/${opinion?.agora?.ago_id ? opinion?.agora?.ago_id : 0}/${opinion?.sond_id}`)
    }


    useEffect(() => {
        const link = findLinkInText(text);
        setLink(link)
    }, [text])


    const findLinkInText = (text) => {
        if (!text) {
            return
        }
        const linkRegex = /(https?:\/\/[^\s]+)/;
        const linkMatch = text.match(linkRegex);
        return linkMatch ? linkMatch[0] : null;
    }



    return (
        <div style={{
            marginBottom: '10px',
            fontSize: '16px',
            cursor: 'pointer',
            paddingLeft: '20px',
            paddingRight: '20px',
        }}>

            {opinion?.agora &&
                <span style={{
                    fontSize: '14px',
                    color: 'white',
                    backgroundColor: color?.primary,
                    paddingBottom: '2px',
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    borderRadius: '10px',
                    marginBottom: '30px'
                }}>
                    {opinion?.agora?.ago_title}
                </span>
            }

            {text &&
                <div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '10px',
                        // marginLeft: hasBackgroundColor
                        //     ? '-20px'
                        //     : '0px',
                        // marginRight: hasBackgroundColor
                        //     ? '-20px'
                        //     : '0px',
                        minHeight: hasBackgroundColor
                            ? '200px'
                            : '0px',
                        backgroundColor: hasBackgroundColor
                            ? opinion?.sond_background
                            : 'white',
                    }}>
                        <p style={{
                            width: '100%',
                            marginBottom: hasBackgroundColor
                                ? '-20px'
                                : '-10px',
                            textAlign: hasBackgroundColor
                                ? 'center'
                                : 'start',
                            color: hasBackgroundColor
                                ? 'white'
                                : 'black',
                            fontSize: hasBackgroundColor
                                ? '22px'
                                : '16px',
                            marginLeft: hasBackgroundColor
                                ? '20px'
                                : '0px',
                            marginRight: hasBackgroundColor
                                ? '20px'
                                : '0px',
                        }}>
                            {viewMore ?
                                <p onClick={goToViewOpinion} >
                                    <TextWithMentionsAndHashtags
                                        text={text}
                                    />
                                </p>
                                :
                                <p onClick={goToViewOpinion}>
                                    <TextWithMentionsAndHashtags
                                        onClick={goToViewOpinion}
                                        text={
                                            text?.length < MAX_LENGHT
                                                ? text
                                                : `${text?.slice(0, MAX_LENGHT)}...`
                                        }
                                    />
                                </p>
                            }


                            {text?.length > MAX_LENGHT &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {!viewMore ?
                                        <button onClick={() => setViewMore(true)} style={{ fontWeight: '600', color: color.primary }}>
                                            {isEnLang ? 'Show more' : 'Voir plus'}
                                        </button>
                                        :
                                        <button onClick={() => setViewMore(false)} style={{ marginTop: '-20px', fontWeight: '600', color: color.primary }}>
                                            {isEnLang ? 'Show less' : 'Voir moins'}
                                        </button>
                                    }
                                </div>
                            }
                        </p>
                    </div>

                    <TextTranslate text={text} />
                </div >
            }

            {
                link &&
                <ReactTinyLink
                    cardSize="large"
                    showGraphic={true}
                    maxLine={2}
                    minLine={1}
                    url={link}
                />
            }

            {
                opinion?.event &&
                <SondEvent
                    ago_id={opinion?.agora?.ago_id}
                    event_opinion_id={opinion?.event?.event_opinion_id}
                    event_user_id={opinion?.event?.event_user_id}
                    event_user={opinion?.event?.owner}
                    event_id={opinion?.event?.event_id}
                    event_title={opinion?.event?.event_title}
                    event_descr={opinion?.event?.event_descr}
                    event_attachments={opinion?.event?.attachments}
                    event_type={opinion?.event?.event_type}
                    event_localisation={opinion?.event?.event_localisation}
                    event_date={opinion?.event?.event_date}
                    event_time={opinion?.event?.event_time}
                    approuved_users_count={opinion?.event?.approuved_users_count}
                    pending_users_count={opinion?.event?.pending_users_count}
                    user_request={opinion?.event?.user_request}
                />
            }

        </div >
    )
}

export default SondCaption