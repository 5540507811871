import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillGift, AiOutlineEye } from 'react-icons/ai'
import Moment from 'react-moment'
import SondAttachment from '../SondAttachment/SondAttachment'
import SondCaption from './SondCaption/SondCaption'
import SondOption from './SondOption/SondOption'
import { useNavigate } from 'react-router-dom'
import { color } from '../../../hooks/Utils/color'
import SondPicture from './SondPicture/SondPicture'
import SondDocs from './SondDocs/SondDocs'
import SondAudio from './SondAudio/SondAudio'
import dayjs from 'dayjs'


const OpinionBody = ({
  opinion,
  viewOpinion,
  setViewOpinion,
  updateOpinion,
  sourceFrom,
}) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [attachments, setAttachments] = useState([])
  const [attachDocs, setAttachDocs] = useState([])


  useEffect(() => {
    const sondFile = {
      sond_filename: opinion?.sond_filename,
      sond_type: opinion?.sond_type,
      sond_picture: opinion?.sond_picture,
      sond_thumbnail: opinion?.sond_thumbnail,
      sond_width: opinion?.sond_width,
      sond_height: opinion?.sond_height,
      sond_size: opinion?.sond_size,
      sond_duration: opinion?.sond_duration,
      sond_extension: opinion?.sond_extension,
      sond_mime_type: opinion?.sond_mime_type,
    }
    const attachFiles = opinion?.attachments?.map(attach => ({
      sond_filename: attach?.attach_filename,
      sond_type: attach?.attach_type,
      sond_picture: attach?.attach_url,
      sond_thumbnail: attach?.attach_thumbnail,
      sond_width: attach?.attach_width,
      sond_height: attach?.attach_height,
      sond_size: attach?.attach_size,
      sond_duration: attach?.attach_duration,
      sond_extension: attach?.attach_extension,
      sond_mime_type: attach?.attach_mime_type,
    }))

    const attach = [sondFile, ...attachFiles]

    setAttachDocs(attach?.filter(file =>
      file?.sond_picture && file?.sond_type === 'document'
    ))
    setAttachments(attach?.filter(file =>
      file?.sond_picture && file?.sond_type !== 'document'
    ))

  }, [opinion?.attachments])



  const goToViewOpinion = () => {
    if (sourceFrom === 'ViewOpinion') {
      return
    }
    navigate(`/opinion/select/${opinion?.agora?.ago_id ? opinion?.agora?.ago_id : 0}/${opinion?.sond_id}`)
  }




  return (
    <div >

      <SondCaption
        text={opinion?.sond_caption}
        opinion={opinion}
        setViewOpinion={setViewOpinion}
        sourceFrom={sourceFrom}
      />

      <div onClick={goToViewOpinion} className='timestamp-container' style={{ cursor: 'pointer' }}>

        <span style={{ color: color.primary, fontSize: '14px', fontWeight: '400', margin: '0px' }}>

          {opinion?.sond_device_os === 'Android' ? t('opinion_for_android') :
            opinion?.sond_device_os === 'iOS' ? t('opinion_for_ios') :
              opinion?.sond_device_os === 'Web' ? t('opinion_for_web') :
                opinion?.sond_device_os === 'Huawei' ? t('opinion_for_huawei') : t('opinion_for_android')}

        </span>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiFillGift size={16} color={color.primary} />
          <span style={{ color: color.primary, fontSize: '14px', fontWeight: '400', margin: '0px', marginLeft: '3px' }}>
            {opinion?.sond_total_recompense}
          </span>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineEye size={16} color={color.primary} />
          <span style={{ color: color.primary, fontSize: '14px', fontWeight: '400', margin: '0px', marginLeft: '3px' }}>
            {opinion?.sond_views}
          </span>
        </div>

        <p style={{ color: '#808080', fontSize: '13px', margin: '0px' }}>
          {dayjs(opinion?.sond_publishing).format('DD MMM YYYY HH:mm')}
        </p>

      </div>



      {/* DOCUMENT ATTACHMENT */}
      <div style={{ marginBottom: '10px' }}>
        {attachDocs?.map((file, index) => {
          return (
            <div key={index?.toString()} style={{ marginRight: '10px', marginBottom: '10px' }}>
              <SondDocs
                name={file?.sond_filename}
                uri={file?.sond_picture}
              />
            </div>
          )
        })}
      </div>

      {opinion?.sond_audio &&
        <div style={{ marginBottom: '15px', marginLeft: '5%', marginRight: '5%' }}>
          <SondAudio
            name={opinion?.sond_filename}
            uri={opinion?.sond_audio}
          />
        </div>
      }


      {!!opinion?.options?.length && (
        <div style={{
          display: 'flex', display: 'flex',
          flexWrap: 'wrap', width: '100%'
        }}>
          {opinion?.options?.map(option => {
            return (
              <SondOption
                key={option?.opt_id}
                sond_id={opinion?.sond_id}
                sond_caption={opinion?.sond_caption}
                sond_user={opinion?.sond_user}

                options={opinion?.options}

                opt_id={option?.opt_id}
                opt_image={option?.opt_image}
                opt_type={option?.opt_type}

                opt_size={option?.opt_size}
                opt_extension={option?.opt_extension}
                opt_duration={option?.opt_duration}
                opt_width={option?.opt_width}
                opt_height={option?.opt_height}
                opt_mime_type={option?.opt_mime_type}

                opt_thumbnail={option?.opt_thumbnail}
                opt_libelle={option?.opt_libelle}
                votes_count={option?.votes_count}

                total_count={opinion?.votes_count}
                can_user_vote={opinion?.can_user_vote}
                get_user_vote={opinion?.get_user_vote}


                updateOpinion={updateOpinion}
              // updateVote={updateVote}
              // isOpinionExpired={isOpinionExpired}
              />
            )

          })}
        </div>
      )}



      {/* {attachments?.length === 0 &&
        <img src={opinion?.sond_picture}
          width={'100%'}
        />
      } */}

      {(attachments?.length === 1) && (
        <SondAttachment
          attachment={attachments[0]}
          height='auto'
        />
      )}

      {(attachments.length === 2) && (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={400}
            />
          </div>
          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[1]}
              height={400}
            />
          </div>
        </div>
      )}

      {(attachments.length === 3) && (
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={400}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[1]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[2]}
              height={200}
            />
          </div>
        </div>
      )}

      {(attachments.length === 4) && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={200}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[2]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[3]}
              height={200}
            />
          </div>
        </div>
      )}


      {(attachments.length === 5) && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={250}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={250}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[2]}
              height={166}
            />
            <SondAttachment
              attachment={attachments[3]}
              height={166}
            />
            <SondAttachment
              attachment={attachments[4]}
              height={166}
            />
          </div>
        </div>
      )}

      {(attachments.length === 6) && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[2]}
              height={200}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[3]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[4]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[5]}
              height={200}
            />
          </div>
        </div>
      )}


      {(attachments.length === 7) && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={266}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={266}
            />
            <SondAttachment
              attachment={attachments[2]}
              height={266}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[3]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[4]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[5]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[6]}
              height={200}
            />
          </div>
        </div>
      )}



    </div>
  )
}

export default OpinionBody